@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer components {
  .primaryButton {
    @apply rounded-md bg-primary-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400;
  }
  .secondaryButton {
    @apply rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50;
  }
}

/* @import './Button.module.css'; */


/* @tailwind base;

@tailwind components;
@tailwind utilities; */

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

/* .p-tabview-nav-container {
    display: none;
} */
/* Find better method to style header */
/* https://www.primefaces.org/primereact/datatable/ */
.p-datatable .p-datatable-header {
  background-color: #fff;
  padding: 10px !important;
}

input,
select,
textarea {
  @apply text-gray-800;
}

/* Latest react date picker shows a span this hides it find a better method to exclude */
/* https://github.com/Hacker0x01/react-datepicker/search?q=alert */

[id^="react-select-"],
.p-chips-input-token input {
  --tw-ring-inset: 0 !important;
}

div.p-chips.p-component.p-inputwrapper {
  min-width: 250px !important;
  max-width: 250px !important;
}

.react-datepicker__aria-live {
  display: none;
}

.card {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.p-dialog-mask {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: transparent !important;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

/* 
.expanded-rows {
    width: calc(100% + 48px) !important;
    margin-left: -24px;
    overflow-x: visible;
}


.row-expansion-cell, [class^="row-expansion-cell-"] {
    text-align: left; 
    padding: 0rem 1.5rem;
    display: inline-block;

}

.row-expansion-cell-1 {
    display: inline-block;
    width: 64px;
    padding: 0;
}

.row-expansion-cell-6 {
    display: inline-block;
    width: 72px;
    padding: 0;
}

.row-expansion-cell-2 {
    min-width: 424.28px !important;
    width: 33.2%;
    padding: 0rem 24px;
}

.row-expansion-cell-3 {
    min-width: 187.22px !important;
    width: 14.65% !important;
    padding: 0rem 1.5rem;
}

.row-expansion-cell-4 {
    min-width: 224.92px !important;
    width: 17.6% !important;
    padding: 0rem 1.5rem;
}

.row-expansion-cell-5 {
    min-width: 305.44px !important;
    width: 23.9% !important;
    padding: 0rem 1.5rem;

}

td {
    width: inherit !important;
}

.keyword-column {
    min-width: 424.28px !important;
    width: 33.2% !important;
}

.page-column {
    min-width: 187.22px !important;
    width: 14.65% !important;
}

.position-column {
    min-width: 224.92px !important;
    width: 17.6% !important;
}

.url-column {
    min-width: 305.44px !important;
    width: 23.9% !important;
}

.select-column {
    width: 2% !important;
} */

/* Used on serp table when competitors are shown */
/* This is forcing a color on various parts of app needs to have select fixed */
/* [id^="pr_id_1_content"] {
    background-color: #F0F8FF !important;
} */

.p-multiselect .p-multiselect-label {
  padding: 0px !important;
}

.scrollbox {
  overflow: hidden;
  padding: 5px 2px 4px 0px;
  position: relative;
}
.scrollbox-inner {
  overflow: hidden;
}
.scrollbox-inner,
.scrollbox:hover {
  visibility: visible;
}
/* ::-webkit-scrollbar{
  width: 5px;
}
::-webkit-scrollbar-thumb {
    background: #F9F4F3;
    border-radius: 10px;
} */
.footer {
  padding: 15px 0 40px 0px;
}
.navbar-main-div-height {
  height: calc(100% - 36px);
}
.unify-button {
  display: inline-flex;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.375rem;
  border-color: rgb(214 211 209);
  background-color: rgb(255 255 255);
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 500;
  color: rgb(55 65 81);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.unify-button:hover {
  background-color: rgb(249 250 251);
}

.unify-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

/* Masonry bricks or child elements */
.grid-item {
  display: inline-block;
  margin: 0 0 1em;
  width: 100%;
}


/* Add this in your global CSS file */
.hideTabs .p-tabview-nav-container{
  display: none !important;
}

/* ad page */

.full-size-image-container img {
  width: auto;
  height: auto;
  display: block; /* Ensures no extra space around the image */
  margin: 0 auto;
}

.ad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
}

.ad-card {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

